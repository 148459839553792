var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DarkWhite"},[_c('v-data-table',{staticClass:"elevation-1 pt-2",attrs:{"headers":_vm.headers,"items":_vm.parList,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mr-2 mt-2",staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","placeholder":"Search PAR number","color":"green darken-2","dense":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]},proxy:true},{key:"item.FundSource",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.FundCode)+" - "+_vm._s(item.SpecificFundSourceDescription)+" ")]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.totalAmount)))])]}},{key:"item.DateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateCreated))+" ")]}},{key:"item.DispenseTypeID",fn:function(ref){
var item = ref.item;
return [(item.DispenseTypeID == 1)?_c('span',[_vm._v("Distributed")]):_vm._e(),(item.DispenseTypeID == 2)?_c('span',[_vm._v("Shared")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.viewPAR(item)}}},[_vm._v(" View ")])],1)]}}],null,true)}),_c('PARModal',{attrs:{"parData":_vm.parData}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }